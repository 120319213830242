<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar
      ><progress-bar
        section="Commune"
        :progress_section="progress_section.commune"
      />
    </template>
    <template #current_page>Commune</template>
    <template #body>
      <div
        class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg"
      >
        Information de la commune
      </div>
      <div class="flex">
        <ValidationObserver v-slot="{ handleSubmit }" class="w-full">
          <form
            @submit.prevent="handleSubmit()"
            class="w-full flex flex-col mt-4 commune-infos-form"
          >
            <div class="flex w-full lg:flex-col">
              <div class="lg:w-full xl:w-8/12 flex flex-col lg:mb-6">
                <div class="w-full flex sm:flex-col space-x-6 sm:space-x-0">
                  <div
                    class="flex flex-col lg:w-full xl:w-10/24 space-y-4 lg:pr-4 sm:pr-0 lg:space-y-6 lg:mb-6"
                  >
                    <pro-input
                      shouldBeHighlighted
                      name="Commune"
                      requirements="required"
                      v-model="informations_commune.commune"
                      label="Commune"
                    />
                    <pro-input
                      shouldBeHighlighted
                      name="Age Moyen"
                      requirements="required"
                      v-model="informations_commune.age_moyen"
                      label="Age Moyen"
                    />

                    <pro-input
                      shouldBeHighlighted
                      name="Ménage moyen"
                      requirements="required"
                      v-model.number="informations_commune.menage_moyen.valeur"
                      label="Ménage moyen"
                      type="number"
                      :unit="informations_commune.menage_moyen.unit"
                    />
                  </div>
                  <div
                    class="flex flex-col lg:w-full xl:w-10/24 lg:space-y-6 space-y-4"
                  >
                    <pro-input
                      shouldBeHighlighted
                      name="Zone pinel"
                      requirements="required"
                      v-model="informations_commune.zone_pinel"
                      label="Zone pinel"
                    />

                    <pro-input
                      shouldBeHighlighted
                      name="Tax habitat"
                      requirements="required"
                      v-model="informations_commune.tax_habitat.valeur"
                      label="Tax habitat"
                      :unit="informations_commune.tax_habitat.unit"
                      type="number"
                    />

                    <pro-input
                      shouldBeHighlighted
                      name="Tax foncière"
                      requirements="required"
                      v-model="informations_commune.tax_fonciere.valeur"
                      label="Tax foncière"
                      :unit="informations_commune.tax_fonciere.unit"
                      type="number"
                    />
                  </div>
                </div>
              </div>
              <div class="sm:w-full lg:w-1/2 xl:w-4/12 flex flex-col">
                <label>Image Commune</label>

                <InputImage
                  :file="informations_commune.image_commun"
                  @upload="saveImage"
                  :selfUploader="true"
                  @deleteImage="informations_commune.image_commun = ''"
                />
                <HighlightMessage
                  :show="isEmptyOrNull(informations_commune.image_commun)"
                />
              </div>
            </div>

            <div>
              <div
                class="text-promy-gray-650 mb-4 mt-6 font-extrabold font-main lg:text-base xl:text-lg"
              >
                Description de la commune
              </div>
              <div class="flex sm:flex-col space-x-6 sm:space-x-0">
                <div class="lg:w-full xl:w-2/7 flex flex-col space-y-4">
                  <pro-input
                    shouldBeHighlighted
                    name="Nom du département"
                    requirements="required"
                    v-model="informations_commune.departement"
                    label="Nom du département"
                  />

                  <pro-input
                    shouldBeHighlighted
                    name="Nom de la région"
                    requirements="required"
                    v-model="informations_commune.region"
                    label="Nom de la région"
                  />

                  <pro-input
                    shouldBeHighlighted
                    name="Surface de la commune"
                    requirements="required"
                    v-model="informations_commune.surface_commune.valeur"
                    label="Surface de la commune"
                    :unit="informations_commune.surface_commune.unit"
                    type="number"
                  />
                </div>
                <div
                  class="lg:w-full xl:w-2/7 flex flex-col space-y-4 sm:mt-4 sm:space-x-0"
                >
                  <pro-input
                    shouldBeHighlighted
                    name="Nombre d'habitants"
                    requirements="required"
                    v-model="informations_commune.nombre_habitants"
                    label="Nombre d'habitants"
                    type="number"
                  />
                  <pro-input
                    shouldBeHighlighted
                    name="Densité"
                    requirements="required"
                    v-model="informations_commune.densite.valeur"
                    label="Densité"
                    unit="hab/km²"
                    class_unit="w-16"
                    type="number"
                  />
                  <pro-input
                    shouldBeHighlighted
                    name="Nom du maire"
                    requirements="required"
                    v-model="informations_commune.nom_maire"
                    label="Nom du maire"
                  />
                </div>
                <div
                  class="lg:w-full xl:w-2/7 flex flex-col space-y-4 sm:mt-4 sm:space-x-0"
                >
                  <pro-input
                    shouldBeHighlighted
                    name="Gentillé"
                    requirements="required"
                    v-model="informations_commune.gentille"
                    label="Gentillé"
                  />
                  <pro-input
                    shouldBeHighlighted
                    name="Communauté urbaine"
                    requirements="required"
                    v-model="informations_commune.communaute_urbaine"
                    label="Communauté urbaine"
                  />
                  <span>
                    <label class="label-date"> Date De Mise À Jour </label>
                    <datepicker
                      class="date-input pt-1 mb-5"
                      v-model="informations_commune.data_insee"
                      valueType="DD-MM-YYYY"
                      format="DD-MM-YYYY"
                    />
                    <HighlightMessage
                      :show="isEmptyOrNull(informations_commune.data_insee)"
                    />
                  </span>
                </div>
              </div>
              <div class="lg:w-full flex flex-col sm:mt-4 sm:space-x-0">
                <div class="flex space-x-3 items-center">
                  <label class="mt-4">Description de la commune</label>
                  <button
                    @click="setDescriptionCommune(true)"
                    v-tooltip.bottom="{
                      content:
                        'synchronisation des données de la description commune',
                      classes: classes,
                    }"
                    type="button"
                    class="rounded-full bg-promy-green-300 text-white px-2 py-1 text-sm cursor-pointer hover:bg-promy-green-200 h-7 w-7"
                  >
                    <svg
                      width="15"
                      height="17"
                      viewBox="0 0 15 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.45166 14.4994C6.75122 14.4994 6.05776 14.3615 5.41064 14.0934C4.76352 13.8254 4.17545 13.4324 3.68017 12.9372C3.18489 12.4419 2.79197 11.854 2.52392 11.2068C2.25588 10.5597 2.11795 9.86612 2.11795 9.16568C2.11795 8.46525 2.25588 7.77164 2.52392 7.12453C2.79197 6.47741 3.18489 5.88948 3.68017 5.3942C4.17545 4.89892 4.76352 4.50599 5.41064 4.23795C6.05776 3.96991 6.75122 3.83198 7.45166 3.83198V6.65138L13.1866 3.33714L7.45166 0.0228271V2.30854C6.09544 2.30854 4.7696 2.71069 3.64195 3.46416C2.51429 4.21764 1.63556 5.28861 1.11655 6.54159C0.597554 7.79457 0.461624 9.17328 0.726209 10.5034C0.990793 11.8336 1.64389 13.0554 2.60288 14.0144C3.56187 14.9734 4.78361 15.6265 6.11376 15.8911C7.44392 16.1556 8.82284 16.0199 10.0758 15.5009C11.3288 14.9819 12.3996 14.103 13.1531 12.9753C13.9066 11.8477 14.3088 10.5219 14.3088 9.16568H12.7854C12.7838 10.5798 12.2214 11.9356 11.2215 12.9355C10.2215 13.9354 8.86578 14.4979 7.45166 14.4994Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>

                <quill-editor
                  ref="myQuillEditor"
                  class="mb-20 md:mb-0 mt-2"
                  v-model="informations_commune.description"
                />
              </div>
            </div>

            <div
              class="flex items-center lg:text-base mt-6 xl:text-lg font-main font-extrabold justify-between border-b-2 xl:pb-5 lg:pb-4 xl:border-promy-gray-325"
            >
              <span class="text-promy-gray-650">Ajouter/Editer un Stat.</span>
              <span
                @click="addStat()"
                class="flex justify-center items-center uppercase cursor-pointer text-white rounded-full border-2 bg-promy-green-300 px-4 py-2 border-promy-green-300 font-main text-sm font-extrabold"
              >
                <span class="">Ajouter un Stat</span>
                <i class="fa fa-plus pl-2 text-sm"></i>
              </span>
            </div>
            <div
              class="flex flex-row lg:flex-col items-center mt-6"
              v-for="(stat, index) in informations_commune.statistiques"
              :key="index"
            >
              <div class="w-1/2 lg:w-full flex flex-col">
                <div class="flex items-center mb-4">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg"
                  >
                    Statistique {{ index + 1 }}
                  </div>
                  <div>
                    <span
                      @click="deleteStats(index)"
                      class="text-promy-red ml-2 cursor-pointer"
                      v-tooltip.right="{
                        content: 'Supprimer ce stat',
                        classes: ['px-2 text-promy-gray-600'],
                      }"
                    >
                      <i
                        class="fa fa-trash-alt lg:text-base xl:pb-1 xl:text-xl"
                      ></i>
                    </span>
                  </div>
                </div>
                <div class="statistique-form flex flex-col w-full space-y-4">
                  <pro-input
                    shouldBeHighlighted
                    class="w-6/10"
                    name="Titre"
                    requirements="required"
                    v-model="stat.title"
                    label="Titre"
                  />
                  <pro-input
                    shouldBeHighlighted
                    class="w-6/10"
                    name="Unité"
                    requirements="required"
                    v-model="stat.unit"
                    label="Unité"
                  />

                  <div class="flex flex-col">
                    <div
                      class="stats-values flex my-2"
                      v-for="(attr, key) in stat.attributs"
                      :key="key"
                    >
                      <div class="flex flex-1 w-auto">
                        <pro-input
                          shouldBeHighlighted
                          class="w-1/2 mr-6"
                          name="Nom"
                          requirements="required"
                          v-model="attr.name"
                          label="Nom"
                        />
                        <pro-input
                          shouldBeHighlighted
                          class="w-1/2"
                          name="Valeur"
                          requirements="required"
                          v-model.number="attr.value"
                          label="Valeur"
                          type="number"
                        />
                      </div>
                      <div class="w-1/12 flex items-end pb-3">
                        <span
                          @click="deleteAttribut(index, key)"
                          class="text-promy-red ml-4 mr-1 cursor-pointer"
                          v-tooltip.right="{
                            content: 'Supprimer cet attribut',
                            classes: ['px-2 text-promy-gray-600'],
                          }"
                        >
                          <i
                            class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div class="mt-3">
                      <span
                        class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-base"
                        @click="addAttibut(stat.attributs)"
                      >
                        <p>Ajouter un champ</p>
                        <span
                          class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-2 py-2 px-2"
                        >
                          <i class="fa fa-plus text-sm"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-1/2 lg:w-full pl-6 lg:pl-0">
                <rounded-chart
                  :chartId="'Statistique-' + index"
                  :label="stat.title"
                  :dataset="getOneProperty(stat.attributs, 'value')"
                  :legends="getOneProperty(stat.attributs, 'name')"
                  :unit="stat.unit"
                />
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import _ from 'lodash'
import { directive as onClickaway } from 'vue-clickaway'
import progress from '@/mixins/dossierPromoteurs/progress'
import { mapGetters } from 'vuex'
import helpers from '@/mixins/helpers'

export default {
  mixins: [progress, helpers],

  directives: {
    onClickaway: onClickaway,
  },

  data() {
    return {
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-sm',
        'bg-white',
        'mb-2',
      ],
      informations_commune: {
        description: '',
        commune: '',
        age_moyen: '',
        menage_moyen: {
          valeur: null,
          unit: 'nbr',
        },
        zone_pinel: '',
        tax_habitat: {
          valeur: null,
          unit: '%',
        },
        tax_fonciere: {
          valeur: null,
          unit: '%',
        },
        departement: '',
        region: '',
        surface_commune: {
          valeur: null,
          unit: 'Km2',
        },
        nombre_habitants: '',
        densite: {
          valeur: null,
          unit: 'hab/km²',
        },
        nom_maire: '',
        gentille: '',
        communaute_urbaine: '',
        data_insee: '',
        statistiques: [],
        image_commun: null,
      },
      globalStatistique: [
        {
          cat: 'socio_professionnelles',
          chartId: 'socio-professionnelles',
          label: 'Catégories Socioprofessionnelles',
          unit: 'Nbr',
          sub_cats: [
            {
              name: 'Agriculteurs exploitant',
              key: 'Population_de_15_ans_ou+CSP_Agriculteurs_exploitants',
            },
            {
              name: 'Artisans, commerçants, chefs d’entreprise',
              key: 'Population_de_15_ans_ou+CSP_Artisans_commercants_chefs_entreprise',
            },
            {
              name: 'Professions intermédiaires',
              key: 'Population_de_15_ans_ou+CSP_Professions_intermediaires',
            },
            {
              name: 'Cadres et professions supérieures',
              key: 'Population_de_15_ans_ou+CSP_Cadres_et_professions_intellectuelles_superieures',
            },
            {
              name: 'Ouvriers',
              key: 'Population_de_15_ans_ou+CSP_Ouvriers',
            },
            {
              name: 'Retraités',
              key: 'Population_de_15_ans_ou+CSP_Retraites',
            },
            {
              name: 'Sans activités',
              key: 'Population_de_15_ans_ou+CSP_Autres_personnes_sans_activite_professionnelle',
            },
          ],
        },
        ,
        {
          cat: 'statut_logement',
          chartId: 'statut-logement',
          label: 'Répartition de la population',
          unit: 'Nbr',
          sub_cats: [
            {
              name: '- de 25 ans',
              key: 'Part_des_pers_agees_moins_de_25_ans',
            },
            {
              name: 'de 25 à 64 ans',
              key: 'Part_des_pers_agees_de_25_a_64_ans',
            },
            {
              name: '+ de 65 ans',
              key: 'Part_des_pers_agees_plus_de_65_ans',
            },
          ],
        },
        {
          cat: 'proportion_appartements_maisons',
          chartId: 'proportion-appartements-maisons',
          label: 'Proportion Appartements/Maisons',
          unit: '%',
          sub_cats: [
            {
              name: 'Maison',
              key: 'Part_des_maisons_dans_le_total_des_logements',
            },
            {
              name: 'Appartement',
              key: 'Part_des_appartements_dans_le_total_des_logements',
            },
          ],
        },
        {
          cat: 'statut_des_habitants',
          chartId: 'statut-des-habitants',
          label: 'Statut des habitants',
          unit: '%',
          sub_cats: [
            {
              name: 'Propriétaires',
              key: 'Part_des_proprietaires_dans_les_res._principales',
            },
            {
              name: 'Locataires',
              key: 'Part_des_locataires_dans_les_res._principales',
            },
          ],
        },
      ],
      requestedCommunes: [],
      isTyping: false,
      errors: [],
      CommuneisOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      CommuneStore: 'dossierpromoteur/getCommune',
      infos_cadastre: 'parcelle/infos_cadastre',
      progress_section: 'dossierpromoteur/getProgressSection',
    }),
    informations() {
      return this.infos_cadastre.informations[0]
    },
    donnees() {
      return this.informations.donnees
    },
    population() {
      return this.donnees.population
    },
  },

  created() {
    this.informations_commune = Object.assign(
      {},
      this.informations_commune,
      this.CommuneStore,
    )

    this.setDescriptionCommune()
    this.setGlobalStatistique(this.CommuneStore.statistiques)
  },
  methods: {
    setDescriptionCommune(force_update = false) {
      if (this.informations_commune.description == '' || force_update) {
        if (this.checkInformationsCommune('commune', 'departement', 'region')) {
          this.informations_commune.description = `<p><strong>${this.informations_commune.commune} </strong> est une commune située dans le département du <strong> ${this.informations_commune.departement}</strong> et la Région <strong> ${this.informations_commune.region} </strong>.</p><br>`
        }
        if (
          this.checkInformationsCommune(
            'surface_commune',
            'nombre_habitants',
            'densite',
          )
        ) {
          this.informations_commune.description += `<p>La commune s’étend sur <strong> ${this.informations_commune.surface_commune.valeur} </strong> km² et compte <strong> ${this.informations_commune.nombre_habitants} </strong> habitants depuis les données officielles de l’INSEE <strong> ${this.informations_commune.data_insee} </strong>, Avec une densité de <strong> ${this.informations_commune.densite.valeur} </strong> habitants par km2 .</p><br>`
        }
        if (
          this.checkInformationsCommune(
            'maire',
            'nom_maire',
            'gentille',
            'communaute_urbaine',
          )
        ) {
          this.informations_commune.description += `<p>Le maire actuel est <strong> ${this.informations_commune.nom_maire} </strong>, Les habitants et habitantes de la commune <strong> ${this.informations_commune.commune} </strong> sont appelés <strong> ${this.informations_commune.gentille} </strong> .</p><br><p>Elle fait partie de la Communauté urbaine <strong> « ${this.informations_commune.communaute_urbaine} »</strong>.</p>`
        }
      }
    },
    checkInfo(value) {
      return value !== null && value !== '' && typeof value !== 'undefined'
    },
    checkInformationsCommune(...args) {
      try {
        return (
          args.filter((arg) => {
            if (typeof this.informations_commune[arg] !== 'object') {
              return this.checkInfo(this.informations_commune[arg])
            } else {
              return this.checkInfo(this.informations_commune[arg]['valeur'])
            }
          }).length > 0
        )
      } catch {
        return false
      }
    },
    setGlobalStatistique(statistiques = this.globalStatistique) {
      let local_statistiques = []
      statistiques.forEach((statistique) => {
        let stat = {
          title: '',
          unit: '',
          attributs: [],
        }
        stat.title = statistique.label ? statistique.label : statistique.title
        stat.unit = statistique.unit
        let sub_cats = statistique.sub_cats
          ? statistique.sub_cats
          : statistique.attributs
        sub_cats.forEach((sub_cat) => {
          if (sub_cat.name != 'Cadres et prof° intellectuelles supérieures') {
            stat.attributs.push({
              name: sub_cat.name,
              value: sub_cat.key
                ? this.population[sub_cat.key].valeur
                : sub_cat.value,
            })
          }
        })
        local_statistiques.push(stat)
      })
      this.informations_commune.statistiques = local_statistiques
    },
    setCommune() {
      this.informations_commune.commune = this.informations.nom
      this.informations_commune.zone_pinel =
        this.donnees.zonage_Pinel.zone_loi_Pinel.valeur
      this.informations_commune.tax_habitat.valeur =
        this.donnees.taxe.Taux_communal_TH.valeur
      this.informations_commune.tax_fonciere.valeur =
        this.donnees.taxe.Taux_communal_TFB.valeur
      this.informations_commune.menage_moyen.valeur =
        this.population.Taille_moyenne_des_menages.valeur

      this.informations_commune.surface_commune.valeur =
        this.informations.surface

      this.informations_commune.densite.valeur = this.population.Densite.valeur

      this.informations_commune.departement = this.informations.departement
      this.informations_commune.nombre_habitants =
        this.population.Population_municipale.valeur
      this.informations_commune.region = this.informations.region
      this.informations_commune.gentille = this.informations.gentile
      this.informations_commune.communaute_urbaine = this.informations.nom_epci
      this.informations_commune.nom_maire = this.informations.maire

      this.setGlobalStatistique()
    },
    searchAfterDebounce: _.debounce(function () {
      this.getCommunes()
    }, 300),
    setCommunes(result) {
      this.informations_commune.commune = result.nom
      this.informations_commune.population = result.population.toString()
      this.CommuneisOpen = false
      this.requestedCommunes = []
    },
    getCommunes() {
      let vm = this

      vm.$http
        .get(
          'https://geo.api.gouv.fr/communes?nom=' +
            vm.informations_commune.commune +
            '&limit=10',
        )
        .then((response) => {
          vm.requestedCommunes = response.data
        })
      vm.CommuneisOpen = true
    },
    addStat() {
      this.informations_commune.statistiques.push({
        title: '',
        unit: '',
        attributs: [
          {
            name: '',
            value: '',
          },
        ],
      })
    },
    deleteStats(index) {
      this.informations_commune.statistiques.splice(index, 1)
    },
    deleteAttribut(index, key) {
      this.informations_commune.statistiques[index].attributs.splice(key, 1)
    },
    addAttibut(array) {
      array.push({ name: '', value: '' })
    },
    away() {
      this.CommuneisOpen = false
    },
    saveInfos() {
      this.$store.commit(
        'dossierpromoteur/setCommune',
        this.informations_commune,
      )
    },
    saveImage(file) {
      this.informations_commune.image_commun = file
    },
    getOneProperty(array, property) {
      return array.map((obj) => {
        if (property === 'value') {
          return obj[property] ? parseFloat(obj[property]) : 0
        }
        return obj[property]
      })
    },
  },
  watch: {
    infos_cadastre: {
      immediate: true,
      handler(value) {
        this.setCommune()
      },
    },
    'informations_commune.commune': _.debounce(function () {
      this.isTyping = false
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.getCommunes()
      }
    },
    informations_commune: {
      deep: true,
      handler() {
        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'commune',
          value: this.progressSection(this.informations_commune),
        })
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveInfos()
    next()
  },
}
</script>
<style lang="scss" scoped>
.commune-infos-form {
  input {
    &:focus {
      @apply border-promy-green-50;
    }

    @screen lg {
      @apply w-full;
    }
    @screen xl {
      width: 100% !important;
    }
  }
  .msg-error {
    @screen lg {
      @apply w-full;
    }
    @screen xl {
      width: 100% !important;
    }
  }
  label {
    @apply text-promy-gray-600 font-extrabold text-xs font-arial;
  }
  .statistique-form {
    .title,
    .type {
      input,
      .msg-error {
        width: 100% !important;
      }
    }
  }
  .label-date {
    @apply capitalize text-xs text-gray-600 font-bold font-arial;
  }
}
</style>
